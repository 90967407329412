import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import Select, { StylesConfig } from "react-select";
import { Switch } from '@headlessui/react'
import { ErrorMessage } from '@hookform/error-message';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { END_POINT_V2 } from 'api/endpoint';
import { useAlert } from 'react-alert'
import { useDebouncedCallback } from 'use-debounce/lib';
import { client } from 'api/client';
import PinInput from 'react-pin-input';
import AuthProvider, { AuthContext } from 'context/auth';
import { getPublicKey } from 'utils/keys';
import { JSEncrypt } from "jsencrypt";
import { publicEncrypt, constants } from 'crypto';
import 'react-html5-camera-photo/build/css/index.css';
import {QrScanner} from '@yudiel/react-qr-scanner';

const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4, color: "#2E3A59", marginRight: 12 }),
  control: (styles) => ({
    ...styles,
    borderColor: '#EBEBF0',
    minHeight: '40px',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px',
    fontSize: 12
  }),
  multiValue: (provided) => ({
    ...provided,
    background: '#F2F9FF',
    borderRadius: '18px',
    textColor: '#2E3A59',
    fontWeight: 400,
  }),
}

export function VNDForm({
  selectedRegion,
  profile
}) {
  const {
    watch,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { t } = useTranslation("common")
  const [banks, setBanks] = useState<any>([])
  const alert = useAlert()
  const [accountInfo, setAccountInfo] = useState<any>(null)
  const [submitting, setSubmitting] = useState(false)
  // const []

  const [selected, setSelected] = useState("environment");
  const [isScan, setScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [isVerifyOTP, showVerifyOTP] = useState(false)
  const numberAccountWatch = watch('number')
  const bankWatch = watch('bank')

  const onSubmit = (val: any) => {
    withdraw(val)
  }

  const getBanks = async () => {
    try {
      const res = await client.get(`${END_POINT_V2.GET_BANKS}`)
      setBanks(res.data?.data)
    } catch (error) {
    }
  }

  const withdraw = async (values) => {
    setSubmitting(true)
    let encrypt = new JSEncrypt();
    const publicKey = await getPublicKey()
    const data = {
      bankCode: process.env.REACT_APP_NODE_ENV ==='production' ? bankWatch?.code : 'BIDV',
      "content": values.content,
      "accountNo": process.env.REACT_APP_NODE_ENV ==='production' ? numberAccountWatch : '2034030440000',
      "accountName": process.env.REACT_APP_NODE_ENV ==='production'?  values.name : 'NGUYEN VAN A',
      "otp": values.otp,
      "amount":  values.amount,
      "type": process.env.REACT_APP_NODE_ENV ==='production'?  selectedRegion?.code : 'VI',
      // "type": "VI",
      // "amount": 1000,
      // "bankCode": "BIDV",
      // "content": "text",
      // "accountNo": "2034030440000",
      // "accountName": "NGUYEN VAN A",
      // "otp": values.otp
    }
    const encryptedData = publicEncrypt(
      {
        key: publicKey,
        padding: constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: "sha256",
      },
      Buffer.from(JSON.stringify(data))
    ).toString("base64");
    
    client.post(`${END_POINT_V2.TRANSFER_MONEY}`, {
      data: encryptedData
    })
      .then(res => {
        setSubmitting(false)
        if (res.data?.data?.error_code !== '000' || res.data?.data?.error) {
          return alert.error(res.data?.data?.message || res.data?.data?.error || t(`GENERAL.${res.data?.message}`))
        }

        alert.success(t("FINANCE.WITHDRAW_SUCCESS"))
      })
      .catch(err => {
        setSubmitting(false)
      })
  }

  const getUserAccount = () => {
    if (!bankWatch || !numberAccountWatch || numberAccountWatch === '') return

    axios.post(`${END_POINT_V2.LOOKUP_USER_BANK}`, {
      accountNumber: numberAccountWatch,
      bin: bankWatch?.bin
    })
      .then(res => {
        setAccountInfo(res.data?.data)
        if (res.data?.data?.code !== '00') {
          return alert.error(t("FINANCE.ACCOUNT_NOT_FOUND"))
        }
        setValue('name', res.data?.data?.data?.accountName)
      })
      .catch(err => {
        alert.error(t("FINANCE.ACCOUNT_NOT_FOUND"))
      })
  }

  const getOtp = ()=>{
    client.post(`${END_POINT_V2.REGISTER_RESEND_OTP}`,{
      phone: profile?.phone,
      resend: 0,
      type:"WITHDRAW_SDK"
    })
    .then(res=>{
      showVerifyOTP(true)
      if(res.data?.errorCode !== '00'){
        return alert.error(t(`GENERAL.${res.data?.message}`))
      }
      return alert.success(t(`GENERAL.${res.data?.message}`))
    })
  }

  const handleScan = async (scanData) => {
    setLoadingScan(true);
    setScan(false)
    if (scanData && scanData !== "") {
      client.get(`${END_POINT_V2.QR_CHECK}`, {data: scanData})
      .then(res => {
        const bank  = banks.find(bank => bank.pin === res.data.cusumer?.bankBin)
        if (bank) {
          setValue("number", res.data?.consumer?.bankNumber)
          setValue("bank", bank)
        }
      })
    }
  };

  useEffect(() => {
    getBanks()
  }, [])

  return (
    <form className=' w-full overflow-auto ' onSubmit={handleSubmit(onSubmit)}>
      <div className=' text-[#00c58d] text-xs mt-2 cursor-pointer text-right mt-4'>{t("FINANCE.SAVED_LIST")} </div>
      <div className='mt-4 w-full flex items-start justify-between'>
        <div className='w-full'>
          <label className=' text-xs' htmlFor="number">{t("FINANCE.ACCOUNT")}</label>
          <div className=" flex gap-5"> 
            <div className=' mt-2'>
              <div className='text-white px-3 py-2 rounded-md bg-[#00c58d] text-xs w-fit'> {t("FINANCE.BANK")}</div>
            </div>
            <div className=' mt-2'>
              <div className='text-white px-3 py-2 rounded-md bg-[#00c58d] text-xs w-fit w-[80px]'>
                <button type='button' 
                    onClick={() => {
                      setScan(!isScan);
                    }}>
                  {t("FINANCE.VIET_QR")}
                </button>
              </div>
            </div>
          </div>
          {
              isScan && (
                <div
                  style={{
                    margin: 'auto',
                    marginTop: 20,
                    width: 300,
                    borderRadius: 16,
                    overflow: 'hidden'
                  }}
                >
                  <QrScanner
                    constraints={{
                      facingMode: 'environment',
                      height: {
                        ideal: 720,
                        max: 1080,
                        min: 640
                      },
                      width: {
                        ideal: 720,
                        max: 1920,
                        min: 640
                      }
                    }}
                    onDecode={(e) => handleScan(e)}
                    scanDelay={100}
                    tracker
                  />
                </div>
              )
            }
        </div>
      </div>

      <div className="py-2 w-full items-end mt-2">
        <label className=' text-xs mr-3' htmlFor="bank">{t("FINANCE.CHOOSE_A_BANK")}</label>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="bank"
          //@ts-ignore
          {...register('bank', {
            required: {
              value: true,
              message: `${t("FINANCE.REQUIRED")}`
            },
          })}
          render={(field) => {
            return (
              <Select
                className=" font-normal text-xs"
                styles={customSelectStyle}
                hideSelectedOptions={false}
                value={field.value || []}
                options={banks}
                closeMenuOnSelect={true}
                menuPlacement="auto"
                placeholder={t("FINANCE.CHOOSE_A_BANK")}
                maxMenuHeight={200}
                onChange={(val) => {
                  field.onChange(val)
                }}
                getOptionLabel={(option) => `${option.name} - ${option.short_name}`}
                getOptionValue={(option) => option.id}
              />
            )
          }}
        />
        <ErrorMessage
          className="text-red-500 text-xs"
          errors={errors}
          name="bank"
          as="p"
        />
      </div>

      <div className='grid grid-cols-2 gap-5 mt-2'> 
        <div className='col-span-2 lg:col-span-1'>
          <label className=' text-xs' htmlFor="number">{t("FINANCE.ACCOUNT_NUMBER")}</label>
          <input
            className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
            type="text"
            id="number"
            name="number"
            onBlur={() => {
              getUserAccount()
            }}
            placeholder={t("FINANCE.ENTER_ACCOUNT_NUMBER")}
            ref={register({ required: true })}
          />
          {errors.number && <p
            className="text-red-500 text-xs"
          >
            {t("FINANCE.REQUIRED")}
          </p>}
        </div>

        <div className='col-span-2 lg:col-span-1'>
          <label className=' text-xs' htmlFor="name">{t("FINANCE.ACCOUNT_NAME")}</label>
          <input
            className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
            type="text"
            id="name"
            name="name"
            // disabled
            readOnly
            placeholder={t("FINANCE.ENTER_ACCOUNT_NAME")}
            ref={register({ required: true })}
          />
          {errors.name && <p
            className="text-red-500 text-xs"
          >
            {t("FINANCE.REQUIRED")}
          </p>}
        </div>
      </div>

      <div className='grid grid-cols-2 gap-5 mt-4'>
        <div className='col-span-2 lg:col-span-1' >
            <label className=' text-xs' htmlFor="amount">{t("FINANCE.AMOUNT")}</label>
            <input
              className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
              type="number"
              id="amount"
              name="amount"
              placeholder={t("FINANCE.ENTER_AMOUNT")}
              ref={register({ required: true })}
            />
            {errors.amount && <p
              className="text-red-500 text-xs"
            >
              {t("FINANCE.REQUIRED")}
            </p>}
        </div>
        <div className="col-span-2 lg:col-span-1 flex w-full justify-end items-center">
          <label className=' text-xs mr-3' htmlFor="remember">{t("FINANCE.REMEMBER")}</label>
          {/* @ts-ignore */}
          <Controller
            control={control}
            name="remember"
            render={(field) => {
              return (
                <Switch
                  checked={getValues("remember")}
                  name="remember"
                  onChange={(val) => field.onChange(val)}
                  className={`${getValues("remember") ? 'bg-teal-900' : 'bg-teal-700'}
                    relative inline-flex h-[18px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span
                    aria-hidden="true"
                    className={`-translate-y-1 shadow-sm bg-[#f5f5f5] ${getValues("remember") ? 'translate-x-4' : '-translate-x-1'}
                    pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              )
            }}
          />
        </div>
      </div>

      <div className='mt-4'>
        <label className=' text-xs' htmlFor="content">{t("FINANCE.CONTENT")}</label>
        <textarea
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          id="content"
          name="content"
          placeholder={t("FINANCE.ENTER_CHOOSE_A_BANK")}
          ref={register({ required: true })}
        />
        {errors.content && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>
      <div className=' flex flex-col items-center mt-2'>
        <div className='flex items-center justify-center'>
            <button type="button" className=' text-xs mt-2 text-green-500 font-bold px-3 py-2 rounded-lg text-center border border-green-400' onClick={getOtp}>Get OTP</button>
        </div>
        {
          isVerifyOTP && 
            <div className=' gap-4 flex justify-center mt-2'>
              <PinInput
              {...register("otp", { required: true })}
              length={6}
              initialValue=""
              secret={false}
              onChange={(value, index) => { }}
              type="numeric"
              inputMode="number"
              inputStyle={{borderColor: '#bababa', borderRadius: 8, width: 40, height: 40}}
              onComplete={(value, index) => {
                setValue("otp", value)
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
        }
          
        {errors.content && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>
      { isVerifyOTP && 
        <div className=' w-full flex justify-center mt-6'>
          <Button >Verify OTP</Button>
        </div>
      }
      <div className=' w-full flex justify-center mt-6'>
        <button  className='text-white px-3 py-2 rounded-md bg-[#00c58d] w-fit text-base font-bold disabled:bg-gray-400'
          disabled={submitting || isVerifyOTP} type="submit">
            {submitting ? t('GENERAL.PROCESSING') : t("FINANCE.SUBMIT")}
        </button>
      </div>
    </form>
  )
}

export function AUDForm({
  selectedRegion,
  profile
}) {
  const {
    watch,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const { t } = useTranslation("common")
  const [banks, setBanks] = useState<any>([])
  const alert = useAlert()
  const [accountInfo, setAccountInfo] = useState<any>(null)
  const [submitting, setSubmitting] = useState(false)
  // const []

  const numberAccountWatch = watch('number')
  const bankWatch = watch('bank')

  const onSubmit = (val: any) => {
    withdraw(val)
  }

  const getBanks = async () => {
    try {
      const res = await client.get(`${END_POINT_V2.GET_BANKS}`)
      setBanks(res.data?.data)
    } catch (error) {
    }
  }

  const withdraw = async (values) => {
    setSubmitting(true)
    let encrypt = new JSEncrypt();
    const publicKey = await getPublicKey()
    const data = {
      bankCode: process.env.REACT_APP_NODE_ENV ==='production' ? bankWatch?.code : 'BIDV',
      "content": values.content,
      "accountNo": process.env.REACT_APP_NODE_ENV ==='production' ? numberAccountWatch : '2034030440000',
      "accountName": process.env.REACT_APP_NODE_ENV ==='production'?  values.name : 'NGUYEN VAN A',
      "otp": values.otp,
      "amount":  values.amount,
      "type": process.env.REACT_APP_NODE_ENV ==='production'?  selectedRegion?.code : 'VI',
      // "type": "VI",
      // "amount": 1000,
      // "bankCode": "BIDV",
      // "content": "text",
      // "accountNo": "2034030440000",
      // "accountName": "NGUYEN VAN A",
      // "otp": values.otp
    }
    const encryptedData = publicEncrypt(
      {
        key: publicKey,
        padding: constants.RSA_PKCS1_OAEP_PADDING,
        oaepHash: "sha256",
      },
      Buffer.from(JSON.stringify(data))
    ).toString("base64");
    
    client.post(`${END_POINT_V2.TRANSFER_MONEY}`, {
      data: encryptedData
    })
      .then(res => {
        setSubmitting(false)
        if (res.data?.data?.error_code !== '000' || res.data?.data?.error) {
          return alert.error(res.data?.data?.message || res.data?.data?.error || t(`GENERAL.${res.data?.message}`))
        }

        alert.success(t("FINANCE.WITHDRAW_SUCCESS"))
      })
      .catch(err => {
        setSubmitting(false)
      })
  }

  const getUserAccount = () => {
    if (!bankWatch || !numberAccountWatch || numberAccountWatch === '') return

    axios.post(`${END_POINT_V2.LOOKUP_USER_BANK}`, {
      accountNumber: numberAccountWatch,
      bin: bankWatch?.bin
    })
      .then(res => {
        setAccountInfo(res.data?.data)
        if (res.data?.data?.code !== '00') {
          return alert.error(t("FINANCE.ACCOUNT_NOT_FOUND"))
        }
        setValue('name', res.data?.data?.data?.accountName)
      })
      .catch(err => {
        alert.error(t("FINANCE.ACCOUNT_NOT_FOUND"))
      })
  }

  const getOtp = ()=>{
    client.post(`${END_POINT_V2.REGISTER_RESEND_OTP}`,{
      phone: profile?.phone,
      resend: 0,
      type:"WITHDRAW_SDK"
    })
    .then(res=>{

      if(res.data?.errorCode !== '00'){

        return alert.error(t(`GENERAL.${res.data?.message}`))
      }

      return alert.success(t(`GENERAL.${res.data?.message}`))
    })
    .catch(err=>{

    })
  }

  useEffect(() => {
    getBanks()
  }, [])

  return (
    <form className=' w-full overflow-auto ' onSubmit={handleSubmit(onSubmit)}>
      <div >
        <label className=' text-xs' htmlFor="amount">{t("FINANCE.AMOUNT")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="number"
          id="amount"
          name="amount"
          placeholder={t("FINANCE.ENTER_AMOUNT")}
          ref={register({ required: true })}
        />
        {errors.amount && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className='mt-4 flex items-start justify-between'>
        <div>
          <label className=' text-xs' htmlFor="number">{t("FINANCE.ACCOUNT")}</label>
          <div className="grid grid-cols-8 gap-2">
            <div className=' mt-2'>
              <div className='text-white px-3 py-2 rounded-md bg-[#00c58d] text-xs'> {t("FINANCE.BANK")}</div>
            </div>
            <div className=' mt-2'>
              {/* <Camera
                // onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
              /> */}
              {/* <div className='text-white px-3 py-2 rounded-md bg-[#00c58d] text-xs'>
                <button type='button'>
                  {t("FINANCE.VIET_QR")}</button></div> */}
            </div>
          </div>
        </div>
        <div className=' text-[#00c58d] text-xs mt-2 cursor-pointer'>{t("FINANCE.SAVED_LIST")} </div>
      </div>

      <div className="mt-4 w-full items-end ">
        <label className=' text-xs' htmlFor="number">{t("FINANCE.ROUTING_CODE")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="text"
          id="number"
          name="number"
          onBlur={() => {
            getUserAccount()
          }}
          placeholder={t("FINANCE.ENTER_ROUTING_CODE")}
          ref={register({ required: true })}
        />
        {errors.number && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className='mt-4'>
        <label className=' text-xs' htmlFor="number">{t("FINANCE.ACCOUNT_NUMBER")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="text"
          id="number"
          name="number"
          onBlur={() => {
            getUserAccount()
          }}
          placeholder={t("FINANCE.ENTER_ACCOUNT_NUMBER")}
          ref={register({ required: true })}
        />
        {errors.number && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className=''>
        <label className=' text-xs' htmlFor="name">{t("FINANCE.ACCOUNT_NAME")}</label>
        <input
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          type="text"
          id="name"
          name="name"
          // disabled
          readOnly
          placeholder={t("FINANCE.ENTER_ACCOUNT_NAME")}
          ref={register({ required: true })}
        />
        {errors.name && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>

      <div className="py-2 flex w-full justify-end">
        <label className=' text-xs mr-3' htmlFor="remember">{t("FINANCE.REMEMBER")}</label>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="remember"
          render={(field) => {
            return (
              <Switch
                checked={getValues("remember")}
                name="remember"
                onChange={(val) => field.onChange(val)}
                className={`${getValues("remember") ? 'bg-teal-900' : 'bg-teal-700'}
                  relative inline-flex h-[18px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span
                  aria-hidden="true"
                  className={`-translate-y-1 shadow-sm bg-[#f5f5f5] ${getValues("remember") ? 'translate-x-4' : '-translate-x-1'}
                  pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            )
          }}
        />
      </div>

      <div className=''>
        <label className=' text-xs' htmlFor="content">{t("FINANCE.CONTENT")}</label>
        <textarea
          className="border mt-1 text-xs py-2 px-2 rounded-md w-full focus:outline-none"
          id="content"
          name="content"
          placeholder={t("FINANCE.ENTER_CHOOSE_A_BANK")}
          ref={register({ required: true })}
        />
        {errors.content && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>
      <div className=''>
        <label className=' text-xs' htmlFor="otp">{'OTP'}</label>
        <div className='grid grid-cols-2 gap-4'><PinInput
          // {...register("otp", { required: true })}
          length={6}
          initialValue=""
          secret={false}
          onChange={(value, index) => { }}
          type="numeric"
          inputMode="number"
          onComplete={(value, index) => {
            setValue("otp", value)
          }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
          <div className='flex items-center'>
            <button type="button" onClick={getOtp}>Send</button>
          </div>
        </div>
        {errors.content && <p
          className="text-red-500 text-xs"
        >
          {t("FINANCE.REQUIRED")}
        </p>}
      </div>
      <div className=' w-full flex justify-center mt-6'>
        <Button disabled={submitting} type="submit">{submitting ? t('GENERAL.PROCESSING') : t("FINANCE.SUBMIT")}</Button>
      </div>
    </form>
  )
}


export default function Domestic() {
  const { t } = useTranslation("common")
  const [regions, setRegions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState([])
  const { profile } = useContext(AuthContext)
  const alert = useAlert()

  useEffect(() => {
    getRegions()
  }, [])

  const getRegions = async () => {
    const res = await axios.get(`${END_POINT_V2.GET_REGIONS}`)
    setRegions(res.data?.data)
  }

  const renderForm = () => {
    if (selectedRegion[0]) {
      switch (selectedRegion[0].code) {
        case 'VN':
          return <VNDForm selectedRegion={selectedRegion[0]} profile={profile} />
        case 'AU':
          return <AUDForm selectedRegion={selectedRegion[0]} profile={profile} />
        default:
          break;
      }
    }
  }

  return (<>
    <Select
      className=" font-normal text-xs"
      styles={customSelectStyle}
      hideSelectedOptions={false}
      value={selectedRegion}
      options={regions}
      closeMenuOnSelect={true}
      menuPlacement="auto"
      placeholder={t("GENERAL.CHOOSE_REGION")}
      maxMenuHeight={200}
      isOptionDisabled={(option) => +option.status !== 1}
      getOptionLabel={(option) => {
        let str = ``
        if (+option.status === 2) {
          str = `(${t("GENERAL.COMING_SOON")})`
        }
        if (+option.status === 3) {
          str = `(${t("GENERAL.DISABLED")})`
        }
        return `${option.name} ${str}`
      }}
      getOptionValue={(option) => option._id}
      onChange={(e: any) => {
        switch (+e?.status) {
          case 1:
            setSelectedRegion([e])
            break;
          case 2:
            alert.error(t("GENERAL.COMING_SOON"))
            break;

          case 3:
            alert.error(t("GENERAL.DISABLED"))
            break;

          default:
            break;
        }
      }}
    />
    {
      renderForm()
    }
  </>
  )
}