import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  FormFields,
  FormLabel,
  FormTitle,
  Error,
} from 'components/FormFields/FormFields';
import { LogoImage, LogoWrapper, BackIconWrapper, Wrapper, FormWrapper } from './Login.style';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Logoimage from 'assets/image/EpisPost.png';
import Select from 'components/Select/Select';
import { client } from 'api/client';
import { Endpoint, END_POINT_V2 } from 'api/endpoint';
import { Notification, KIND } from "baseui/notification";
import { CheckMark } from 'assets/icons/CheckMark';
import { ArrowLeftRound } from 'assets/icons/ArrowLeftRound';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { PinCode } from "baseui/pin-code";
import { countryCodeSelectOptions } from 'utils/constant';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
const initialValues = {
  "email": "",
  "phoneNumber": "",
  "password": "",
  "confirmPassword": "",
};

const getRegisterValidationSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string().required(t("FORM.REQUIRED")).email(t("FORM.REUIRED_EMAIL_NOT_INVALID")),
    phoneNumber: Yup.string().required(t("FORM.REQUIRED")),
    password: Yup.string().required(t("FORM.REQUIRED")).min(10, t("FORM.PASSWORD_MUST_BE_AT_LEAST_10_CHARACTER")),
    confirmPassword: Yup.string().required(t("FORM.REQUIRED")).oneOf([Yup.ref('password'), null], t("FORM.PASSWORD_NOT_MATCH")),
  });
};

const MyInput = ({ field, form, ...props }) => {
  return <Input {...field} {...props} />;
};

export default function Register() {
  const {t} = useTranslation('common')
  const [countryCode, setCountryCode] = useState([
    { value: '+61', label: '+61 (AUD)', countryCode: 'AU'}
  ])
  const [errorRequestMsg, setErrorRequestMsg] = useState('')
  const [verified, setVerified] = useState(false)
  const [phone, setPhone] = useState('61484849495')
  // const [code, setCode] = useState(["", "", "", "", "", ""])

  // const [openModalVerifyCode, setOpenModalVerifyCode] = useState(false)
  const [openModalRegisterSuccess, setOpenModalRegisterSuccess] = useState(false)
  const [errorUnSelectedCountryCode, setErrorUnSelectedCountryCode] = useState(false);

  const register = async (values, { setSubmitting }) => {
    setErrorUnSelectedCountryCode(false)
    const { email, password, confirmPassword, phoneNumber } = values
    const phone = `${countryCode[0].value.split('+')[1]}${phoneNumber.replace(/^0+/, '')}`

    const params = {
      phone,
      email,
      password,
      confirmPassword: confirmPassword,
      country: countryCode[0].countryCode
    }

    await client
      .post(`${END_POINT_V2.REGISTER}`, params)
      .then((result) => {
        if (result.status === 200) {
          setSubmitting(false)
          setOpenModalRegisterSuccess(true)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        if (error.response && error.response.data) {
          setErrorRequestMsg(error?.response?.data?.errors[0]?.msg)
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setErrorRequestMsg('')
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const sendVerifyPhoneNumber = async (values, { setSubmitting }) => {
    const { phoneNumber } = values
    setErrorRequestMsg('')
    const phoneVerify = `${countryCode[0].value}${phoneNumber.replace(/^0+/, '')}`
    setPhone(phoneVerify)
    await client
      .post(`${Endpoint.REGISTER_VERIFY_PHONE_NUMBER}`, { phoneNumber: phoneVerify })
      .then((result) => {
        if (result.status === 200) {
          // setOpenModalVerifyCode(!openModalVerifyCode)
          setSubmitting(false)
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          setErrorRequestMsg(error.response.data.message)
          setSubmitting(false)
        }
      });
  }

  // const verifyCode = async () => {
  //   await client
  //     .post(`${Endpoint.REGISTER_VERIFY_CODE}`, { phoneNumber: phone, code: code.join('') })
  //     .then((result) => {
  //       if (result.status === 200) {
  //         setVerified(true)
  //         // setOpenModalVerifyCode(!openModalVerifyCode)
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.data.message) {
  //         // setOpenModalVerifyCode(!openModalVerifyCode)
  //         setErrorRequestMsg(error.response.data.message)
  //       }
  //     });
  // }

  const handleSubmit = (values, { setSubmitting }) => {
    try {
      if (!Object.keys(countryCode).length) {
        setErrorUnSelectedCountryCode(true)
        setSubmitting(false)
      } else {
        if (!verified) {
          register(values, { setSubmitting })
        } else {
          sendVerifyPhoneNumber(values, { setSubmitting })
        }
      }
    }
    catch (e) {
      setSubmitting(false)
    }
  }

  const handleCountryCode = ({ value }) => {
    setCountryCode(value)
  }

  return (
    <Wrapper>
      <FormWrapper>

      {openModalRegisterSuccess &&
        <Modal
          isOpen={openModalRegisterSuccess}
          title="Register Successfully!"
          closeable={false}
          action={() => {window.location.href='/login'}}
          buttonText="Login Now"
        >
        </Modal>
      }
      {errorRequestMsg &&
        <Notification
          kind={KIND.negative}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                width: '100%',
              }),
            },
          }}
        >
          {errorRequestMsg}
        </Notification>
      }
      {/* <BackIconWrapper onClick={() => goToLogin()}> */}
      <a href='/login'>
        <ArrowLeftRound />
        </a>
      {/* </BackIconWrapper> */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ errors, status, touched, isSubmitting }) => (
          <Scrollbars autoHide>
            <FormStyled>
              <FormFields>
                <LogoWrapper>
                  <LogoImage src={Logoimage} alt="epispost" />
                </LogoWrapper>
                <FormTitle>{t("AUTH.REGISTER_ACCOUNT")}</FormTitle>
              </FormFields>

              <FormFields>
                <FormLabel>Email</FormLabel>
                <Field
                  type="email"
                  name="email"
                  component={MyInput}
                  placeholder="Ex: demo@demo.com"
                />
                {errors.email && touched.email && (
                  <Error>{errors.email}</Error>
                )}
              </FormFields>
              {/* <FormFields>
                <FormLabel>Full Name</FormLabel>
                <Field
                  name="full_name"
                  component={MyInput}
                  placeholder="Ex: John A"
                />
                {errors.full_name && touched.full_name && (
                  <Error>{errors.full_name}</Error>
                )}
              </FormFields> */}
              <FormFields>
                <FormLabel>{t("AUTH.PHONE")}
                  {/* {!verified && <CheckMark />} */}
                </FormLabel>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Select
                    options={countryCodeSelectOptions}
                    labelKey="label"
                    valueKey="value"
                    placeholder="Ex: +61"
                    value={countryCode}
                    searchable={false}
                    onChange={handleCountryCode}
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          width: '50%',
                        }),
                      },
                    }}
                    clearable={false}
                  />
                  <Field
                    name="phoneNumber"
                    component={MyInput}
                    placeholder="Ex: 484847879"
                  />
                </div>

                {errors.phoneNumber && touched.phoneNumber && (
                  <Error>{errors.phoneNumber}</Error>
                )}
                {errorUnSelectedCountryCode && (
                  <Error>{t("AUTH.PLEASE_SELECT_COUNTRY_CODE")}</Error>
                )}
              </FormFields>
              <FormFields>
                <FormLabel>{t("AUTH.PASSWORD")}</FormLabel>
                <Field
                  type="password"
                  name="password"
                  component={MyInput}
                  placeholder="Ex: demo"
                />
                {errors.password && touched.password && (
                  <Error>{errors.password}</Error>
                )}
              </FormFields>
              <FormFields>
                <FormLabel>{t("AUTH.CONFIRM_PASSWORD")}</FormLabel>
                <Field
                  type="password"
                  name="confirmPassword"
                  component={MyInput}
                  placeholder="Ex: demo"
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <Error>{errors.confirmPassword}</Error>
                )}
              </FormFields>
              <Button
                type="submit"
                disabled={isSubmitting}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: '100%',
                      marginLeft: 'auto',
                      borderTopLeftRadius: '3px',
                      borderTopRightRadius: '3px',
                      borderBottomLeftRadius: '3px',
                      borderBottomRightRadius: '3px',
                    }),
                  },
                }}
              >
                {!verified ? t("AUTH.REGISTER") : t("AUTH.VERIFY_PHONE_NUMBER")}
              </Button>
            </FormStyled>
          </Scrollbars>
        )}
        validationSchema={() =>getRegisterValidationSchema(t)}
      />
      </FormWrapper>
    </Wrapper>
  );
}

const FormStyled = styled(Form)`
  padding: 0px 10px;
`
