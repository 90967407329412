export const BASE_API_URL = 'https://online-gate.epispost.com.au/api';
export const BASE_API_URL_V2 = process.env.REACT_APP_API_URL || 'https://customersdk.myepis.cloud';
export const BASE_API_URL_V3 = process.env.REACT_APP_API_V3_URL || 'https://api-sta.epispost.com'
export const BASE_API_URL_V4 = 'https://customer-dev.myepis.cloud'

export const Endpoint = {
  GET_PROVINCE_AUD: `${BASE_API_URL}/lookup-zipcode`,
  LOGIN: `${BASE_API_URL}/auth/login`,
  GET_USER_INFO: `${BASE_API_URL}/auth/user`,
  GET_CATEGORIES_VN: `${BASE_API_URL}/categories/vn`,
  GET_CATEGORIES_AU: `${BASE_API_URL}/categories/au`,
  GET_FEE_CALCULATION_VN: `${BASE_API_URL}/fee-calculation`,
  GET_FEE_CALCULATION_AUD: `${BASE_API_URL}/fee-ghn`,
  GET_PROVINCE_VN: `${BASE_API_URL}/resource/provinces`,
  GET_DISTRICT_VN: `${BASE_API_URL}/resource/districts`,
  GET_WARD_VN: `${BASE_API_URL}/resource/wards`,
  GET_PAYMENT_TYPE: `${BASE_API_URL}/resource/payment-types`,
  CREATE_ORDER_VN_TO_AUD: `${BASE_API_URL}/orders`,
  REGISTER_VERIFY_PHONE_NUMBER: `${BASE_API_URL}/send-verify-code`,
  REGISTER_VERIFY_CODE: `${BASE_API_URL}/verify-phone-number`,
  REGISTER: `${BASE_API_URL}/register`,
  VERIFY_EMAIL: `${BASE_API_URL}/email/verify`,
  RESEND_EMAIL: `${BASE_API_URL}/email/resend`,
  SEND_REQUEST_FORGOT_PASSWORD: `${BASE_API_URL}/send-reset-email`,
  RESET_PASSWORD: `${BASE_API_URL}/reset-password`,
  CREATE_ORDER_VN_TO_AU: `${BASE_API_URL}/orders/vn`,
  CREATE_ORDER_AU_TO_VN: `${BASE_API_URL}/orders/au`,
  GET_ORDERS: `${BASE_API_URL}/orders`,
  GET_ORDER_STATUS_VN: `${BASE_API_URL}/order-status/vn`,
  GET_ORDER_STATUS_AU: `${BASE_API_URL}/order-status/au`,
  TRACKING_ORDER: `${BASE_API_URL}/order/lookup`,
}

export const END_POINT_V2 = {
  REGISTER: `${BASE_API_URL_V2}/api/auth/register`,
  REGISTER_RESEND_OTP: `${BASE_API_URL_V2}/api/auth/send_otp`,
  LOGIN: `${BASE_API_URL_V2}/api/auth/login`,
  CONFIRM_OTP: `${BASE_API_URL_V2}/api/auth/confirm`,
  GET_PROVINCES_VN: `${BASE_API_URL_V2}/api/locations/provinces`,
  GET_DISTRICTS_VN: (provinceId) => `${BASE_API_URL_V2}/api/locations/${provinceId}/districts`,
  GET_WARDS_VN: (districtId) => `${BASE_API_URL_V2}/api/locations/${districtId}/wards`,
  PROFILE: `${BASE_API_URL_V2}/api/auth/profile`,
  GET_CATEGORIES_AU: `${BASE_API_URL_V2}/api/categories/list`,
  GET_CATEGORIES_VN: `${BASE_API_URL_V2}/api/categories/list`,
  GET_PAYMENT_TYPES: `${BASE_API_URL_V2}/api/payments/list`,
  GEN_BALE_CODE: `${BASE_API_URL_V2}/api/vanchuyen/gen-bale-code`,
  GET_FEE_CALCULATION_AUD: `${BASE_API_URL_V2}/api/vanchuyen/est-price`,
  GET_FEE_CALCULATION_VND: `${BASE_API_URL_V2}/api/vanchuyen/est-price`,
  CREATE_ORDER_AU_TO_VN: `${BASE_API_URL_V2}/api/vanchuyen/au`,
  CREATE_ORDER_VN_TO_AU: `${BASE_API_URL_V2}/api/vanchuyen/vi`,
  CREATE_USER_ORDER_AU: `${BASE_API_URL_V2}/api/users/address-au`,
  CREATE_USER_ORDER_VI: `${BASE_API_URL_V2}/api/users/address-vi`,
  QR_CHECK: `${BASE_API_URL_V2}/api/banks/detection-account`,

  GET_SENDER: `${BASE_API_URL_V2}/api/users/sender-address`,
  GET_RECEIVER: `${BASE_API_URL_V2}/api/users/receiver-address`,

  GET_ORDERS: `${BASE_API_URL_V2}/api/vanchuyen/orders`,

  GET_ORDER: `${BASE_API_URL_V2}/api/vanchuyen/orders/detail`,

  GET_HISTORIES_ORDER: `${BASE_API_URL_V2}/api/vanchuyen/histories`,

  TRACKING_ORDER: `${BASE_API_URL_V2}/api/vanchuyen/tracking-order`,

  CREATE_ACCOUNT: `${BASE_API_URL_V2}/api/accounts/create-account`,
  GET_ACCOUNTS: `${BASE_API_URL_V2}/api/users/accounts`,
  GET_TRANSACTIONS: `${BASE_API_URL_V2}/api/transactions/list`,
  GET_BALANCE: `${BASE_API_URL_V2}/api/transactions/balance`,
  GET_REGIONS: `${BASE_API_URL_V2}/api/regions/list`,
  GET_BANKS: `${BASE_API_URL_V2}/api/banks/vn`,
  LOOKUP_USER_BANK: `${BASE_API_URL_V2}/api/accounts/get-user-account`,
  TRANSFER_MONEY: `${BASE_API_URL_V2}/api/transactions/transfer-money`,
  GET_OTP_WITHDRAW: `${BASE_API_URL_V2}/api/auth/get-otp-withdraw`,

  VERIFY_EKYC: `${BASE_API_URL_V2}/api/users/ekyc/verify`,
  GET_INVOICE: `${BASE_API_URL_V2}/api/payments/invoice`,

  GET_PRODUCTS_PLAN: `${BASE_API_URL_V2}/api/payments/product`,
  GET_PRODUCTS_PRICE: `${BASE_API_URL_V2}/api/payments/price`,

  CREATE_FREE_SUBSCRIPTION: `${BASE_API_URL_V2}/api/payments/subscription-free`,
  CREATE_SUBSCRIPTION: `${BASE_API_URL_V2}/api/payments/subscription`,

  UPDATE_SUBSCRIPTION: `${BASE_API_URL_V2}/api/payments/plan`,
  PAYMENT: `${BASE_API_URL_V2}/api/payments`,

  GET_CREATOR_PAYMENT_METHODS: `${BASE_API_URL_V2}/api/creators/payment-methods`,
  GET_CREATOR_DETAIL: `${BASE_API_URL_V2}/api/creators/creator-detail`,

  GET_IP: `https://ip-detect.myepis.cloud`,
  CREATOR_TRANSACTION: `${BASE_API_URL_V2}/api/creators/transaction`,
}

export const END_POINT_V3 = {
  GET_ORDER: `${BASE_API_URL_V3}/api/order`,

  GET_ORDER_HISTORIES: `${BASE_API_URL_V3}/api/order`,
}

export const END_POINT_V4 = {
  LOGIN: `${BASE_API_URL_V4}/api/v1/auth/login`,
  VERIFY_OTP: `${BASE_API_URL_V4}/api/v1/auth/verify-login`,
  PROFILE: `${BASE_API_URL_V4}/api/v1/auth/profile`
}