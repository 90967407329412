// **************** ROUTE CONSTANT START **************************
// General Page Section
export const HOME = '/home';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const ORDER_DETAIL = '/order-detail';
export const RESET_PASSWORD = '/reset-password';
export const TRACKING_ORDER = '/tracking-order';
export const FINANCE = '/finance';
export const USER_DETAIL = '/user';
export const VERIFY_EMAIL = '/verify-email';
export const SUBSCRIPTION = '/subscription'
export const CREATOR='/creator/:id'
export const PAYMENT='/payment/:status'
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';


// SCREEN TYPE
export const LOGIN_SCREEN = 0;
export const FORGOT_PASSWORD_SCREEN = 1;
export const REGISTER_SCREEN = 2;

// CURRENCY TYPE
export const currencyType = [
  { value: 'VND', name: 'VND' },
  { value: 'AUD', name: 'AUD' },
];

export const orderTypeSelectOptions = [
  { value: 'vi', name: 'Viet Nam to Australia' },
  { value: 'au', name: 'Australia to Viet Nam' },
  { value: 'vds', name: 'VietNam Domestic Shipping' },
  { value: 'ads', name: 'Australia Domestic Shipping' },
];

export const statusRequest = {
  FOUR01: 401,
}