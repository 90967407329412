import axios from 'axios';

export const setToken = async (token = '') => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const clearToken = async () => {
  axios.defaults.headers.common['Authorization'] = '';
};

const requestAbortCode = 'ECONNABORTED';

axios.defaults.baseURL = '';
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.timeout = 20000;

const RequestClient = class {

  constructor() {
    this.init();
  }
  async init() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('epis-token')}`;
  }

  async headers(params) {
    let keys = Object.keys(params);
    keys.map((key) => {
      return axios.defaults.headers.common[key] = params[key];
    });
  }

  async login(endpoint, bodyData) {
    let response = await fetch(endpoint, {
      method: 'POST',
      body: bodyData,
    });

    return response;
  }

  async get(endpoint, params = {}) {
    try {
      const response = await axios.get(endpoint, params);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(endpoint, body: {}, headers: {}) {
    try {
      const response = await axios.post(endpoint, body, { 
        headers: {
          ...headers
        }
       });
      return response;
    } catch (error) {
      this.handleError(error);
    }

  }

  async put(endpoint, body: {}, params = {}) {
    try {
      const response = await axios.put(endpoint, body, params);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  async patch(endpoint, body: {}, params = {}) {
    try {
      const response = await axios.patch(endpoint, body, params);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  async delete(endpoint, body) {
    try {
      const response = await axios.delete(endpoint, { data: body });
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  handleError(error) {
    if (error.response && error.response.status === 401) {
    }
    if (error.code === requestAbortCode || ('response' in error && error.response === undefined)) {
      error.recall = true;
    }
    throw error;
  }

  async postFormData(endpoint, body, params = {}) {
    try {
      const response = await axios.post(endpoint, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }
};

const client = new RequestClient();

export { client };
